<style>
.c-table-header {
  border-radius: 5px;
  border: 2px solid #7f8387;
  padding: 1rem;
}
.c-table-title {
  position: absolute;
  margin-top: -27px;
  margin-left: 1rem;
  z-index: 1;
  cursor: pointer;
}
.c-table-exfilters {
  transition: all 1s;
}
.c-table-spacer {
  margin-top: .5rem;
}
.c-table-st-clear {
  cursor: pointer;
}
.c-table-progress-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: -1rem;
  margin-right: auto;
  margin-top: 3rem;
}
.c-table-progress-overlay.active {
  display: block;
}
@media (max-width: 1024px) {
  .c-table-sm-spacer {
    margin-top: .5rem;
  }
}
</style>
<script>
import VueSlideBar from "vue-slide-bar";
import { OrbitSpinner } from 'epic-spinners'
import {mapGetters} from "vuex";

export default {
  props: ['banlist_id', 'options'],
  components: {
    VueSlideBar,
    OrbitSpinner
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    gryphonChartOptions() {
      return {}
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/overview`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.banlist = data.banlist;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async fetchData() {
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.add('active');
      let url = new URL(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/protocol-query`);
      url.searchParams.append('page', this.currentPage);
      url.searchParams.append('limit', this.perPage);
      url.searchParams.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
      url.searchParams.append('sort', JSON.stringify({
        key: this.sortBy,
        dir: this.sortDesc ? -1 : 1
      }));
      url.searchParams.append('query', JSON.stringify(this.query));
      let _queryInit = (new Date).getTime();
      this.items = await fetch(url, {credentials: 'include'})
          .then(res => {
            if(res.status === 429) {
              this.$toast.warning(this.$t('error.server.ratelimit.short'));
            }
            return res.json();
          })
          .then(data => {
            this.totalItems = parseInt(data.meta.total_items, 10)
            return data.entries;
          })
          .then(items => items)
          .catch(error => {
            this.query.delta = 0.0;
            this.$toast.error('Failed to contact query service');
            if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
          });

      this.query.delta = (new Date).getTime() - _queryInit;
      if(this.$refs.queryLoadingIndicator) this.$refs.queryLoadingIndicator.classList.remove('active');
    },
    currentDateTime() {
      let date = new Date();
      return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
    },
    async clearSearchTerm() {
      this.query.search_term = null;
      await this.adhocSearch();
    },
    async adhocSearch() {
      await this.fetchData();
    },
    filtersToggle() {
      this.query.full = !this.query.full;
    }
  },
  computed: {
    ...mapGetters(['getDTLocale']),
    positionQuery: {
      get() {
        return `${this.query.position.coordinates[0]}, ${this.query.position.coordinates[1]}`;
      },
      set(val) {
        let f = val.match('^(-?\\d+(\\.\\d+)?),\\s*(-?\\d+(\\.\\d+)?)$');
        if(f === null) return this.$refs.positionQueryInput.$el.classList.add('is-invalid');
        else this.$refs.positionQueryInput.$el.classList.remove('is-invalid');
        let x = f[1];
        let y = f[3];
        this.query.position.coordinates = [x, y];
      }
    }
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortBy: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    },
    sortDesc: {
      handler: function(value) {
        this.fetchData().catch(error => {
          console.error(error)
        })
      }
    }
  },
  mounted() {
    //this.getData();
    this.fetchData().catch(error => {
      console.error(error)
    })
  },
  data() {
    return {
      ready: false,
      error: false,
      banlist: null,
      // Table
      items: [],
      fields: [
        {
          key: 'date',
          label: this.$t('banmanager.view.protocol.columns.date'),
          sortable: true
        },
        {
          key: 'action',
          label: this.$t('banmanager.view.protocol.columns.action'),
          sortable: true
        },
        {
          key: 'user',
          label: this.$t('banmanager.view.protocol.columns.user'),
          sortable: true
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "date",
      sortDesc: true,

      // Query
      queryDateStart: false,
      query: {
        delta: 0.0,
        full: false,
        search_term: null,
        date: {
          start: {
            enabled: false,
            value: this.currentDateTime()
          },
          end: {
            enabled: false,
            value: this.currentDateTime()
          }
        },
        position: {
          available: false,
          enabled: false,
          coordinates: [0.0, 0.0],
          radius: 100.0
        }
      }
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="c-table-header">
        <div class="c-table-title" v-on:click="filtersToggle()">
          <span class="badge badge-primary">
            <h6 class="mb-0 mt-0">
              {{ $t('components.table.filters.title') }}
              <small>
                {{ $t('components.table.filters.details') }}
              </small>
            </h6>

          </span>
        </div>
        <!-- Primary search term -->
        <div class="c-table-spacer"/> <!-- Spacer -->
        <div class="row">
          <div class="col-lg col-sm-12">
            <div class="mt-lg-2">
              {{$t('components.table.filters.search_term')}}
              <span class="badge badge-primary mb-1 c-table-st-clear" v-on:click="clearSearchTerm()">
                {{ $t('components.table.filters.clear') }}
              </span>
            </div>
            <b-form-input
                for="text"
                v-model="query.search_term"
                :placeholder="$t('components.table.filters.search_term')"
                v-on:keyup="$event.keyCode === 13 ? fetchData() : null"
            />
          </div>
        </div>
        <div class="c-table-exfilters" v-if="query.full">
          <!-- Date range -->
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row justify-content-center">
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                      switch
                      v-model="query.date.start.enabled"
                      class="mt-lg-2"
                  >
                    {{ $t('components.table.filters.date.start') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <b-form-input
                      id="date-time"
                      v-model="query.date.start.value"
                      type="datetime-local"
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="c-table-sm-spacer"/> <!-- Spacer -->
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                    switch
                    v-model="query.date.end.enabled"
                    class="mt-lg-2"
                  >
                    {{ $t('components.table.filters.date.end') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <b-form-input
                    id="date-time"
                    v-model="query.date.end.value"
                    type="datetime-local"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Special features, GEO etc. -->
          <template v-if="query.position.available">
          <div class="c-table-spacer"/> <!-- Spacer -->
          <div class="row justify-content-center">
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <b-form-checkbox
                      switch
                      v-model="query.position.enabled"
                      class="mt-lg-2"
                  >
                    {{ $t('components.table.filters.pos.coords') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <div class="input-group">
                    <b-form-input
                        v-model="positionQuery"
                        ref="positionQueryInput"
                    />
                    <div class="invalid-feedback">
                      <span>
                        {{ $t('components.table.filters.pos.error') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-lg-3 col-sm-12 text-lg-center">
                  <div class="mt-lg-2">
                    {{ $t('components.table.filters.pos.radius') }}
                  </div>
                </div>
                <div class="col-lg col-sm-12">
                  <div class="c-table-sm-spacer"/> <!-- Spacer -->
                  <vue-slide-bar v-model="query.position.radius" :min="0" :max="1000" class="mt-n2" />
                </div>
              </div>
            </div>
          </div>
          </template>
        </div>
        <!-- Button -->
        <div class="c-table-spacer"/> <!-- Spacer -->
        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-12">
            <button class="btn btn-primary btn-block" v-on:click="adhocSearch()">
              <i class="fad fa-search"></i>
              {{ $t('components.table.filters.search') }}
            </button>
          </div>
        </div>
        <div class="float-right" v-if="query.delta > 0">
          <small>Query duration {{ query.delta }}ms</small>
        </div>
      </div>
      <div class="mt-2">
        <div class="c-table-progress-overlay" ref="queryLoadingIndicator">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <orbit-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
            </div>
          </div>
        </div>
        <b-table class="table-responsive-sm" show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
          <template #empty="scope">
            <h4>{{ $t('components.table.filters.empty') }}</h4>
          </template>
          <template #cell(date)="data">
            {{ $d(parseDate(data.item.date), 'datetime', getDTLocale()) }}
          </template>
          <template #cell(user)="data">
            <template v-if="data.item.user">
              <router-link :to="{name: 'profile', params: {cftools_id: data.item.user.cftools_id}}">
                <img
                    :src="data.item.user.avatar"
                    alt="profile picture"
                    class="rounded-circle avatar-xs"
                >
                {{ data.item.user.display_name }}
              </router-link>
            </template>
            <template v-else>
              <i>{{ $t('banmanager.view.protocol.no_user') }}</i>
            </template>
          </template>
          <template #cell(action)="data">
            <b class="text-lowercase text-code font-size-14 text-white">
              {{ data.item.action }}
            </b>
          </template>
        </b-table>
      </div>
      <b-pagination class="float-right" size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
    </div>
  </div>
</template>
